import axios from 'axios';
import { getAccessTokenSilently, getIsAuthenticated, loginWithRedirect } from '@/auth';

export const BASE_URL = config.VUE_APP_BASE_URL || process.env.VUE_APP_BASE_URL || '/api';

export function useAxios() {
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.common['Accept'] = 'application/json';

  axios.interceptors.request.use(
    async (config) => {
      const isAuthenticated = getIsAuthenticated();
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  )

  return axios;
}

/**
 * Composable wrapper for making API calls and updating a store[key] with the status of the call and the response.
 *
 * @param withStore The store to update with the status of the request
 * @param requestStateKey The key inside the store to update with the status of the request
 * @param withMultipart If true, the request will be sent as multipart/form-data
 * @param withErrorCallback Callback to handle request errors
 * @returns A request promise
 */
export function useAPI(withStore = null, requestStateKey = null, withMultipart = false, withErrorCallback = null) {
  function patchStore(store, key, value) {
    if (store && key) {
      store.$patch({ [key]: value });
    }
  }

  function callAPI(method, ...args) {
    patchStore(withStore, requestStateKey, inFlightAPIRequest);

    const axios = useAxios();

    return new Promise(async (resolve, reject) => {
      const configArgsIndex = withMultipart ? 2 : 1;
      const config = {...args[configArgsIndex] || {} };

      config.headers = {
        ...config.headers || {},
      }

      // Add Multipart headers to any existing headers passed in
      if (withMultipart) {
        config.headers = {
          ...config.headers,
          'Content-Type': 'multipart/form-data',
        };
      }

      args[configArgsIndex] = config;

      // Call the axios method and arguments passed in
      axios[method](...args).then((resp) => {
        patchStore(withStore, requestStateKey, { ...successfulAPIRequest, response: resp });
        resolve(resp);
      }).catch((err) => {
        patchStore(withStore, requestStateKey, { ...failedAPIRequest, error: err });

        if (typeof withErrorCallback === 'function') {
          withErrorCallback(err);
        } else {
          if (err.message === 'Request aborted') {
            return;
          }

          reject(err);
        }
      });
    });
  }

  // Forward all useApi() calls to the callAPI function instead
  return new Proxy({}, {
    get: function (target, propertyName) {
      // The extra "function" is needed to make "arguments" accessible to the callAPI function
      // otherwise "arguments" will contain whatever was passed to the "get" handler instead.
      return function () {
        return callAPI(propertyName, ...arguments);
      };
    },
  });
}

/**
 * Composable wrapper for making authenticated API calls. This is a wrapper around useAPI() that
 * redirects to the signin page if the user is not authenticated (ie. the API call returns a 401).
 *
 * @param withStore The store to update with the status of the request
 * @param requestStateKey The key inside the store to update with the status of the request
 * @param withMultipart If true, the request will be sent as multipart/form-data
 * @returns A request promise
 */
export function useSecureAPI(withStore = null, requestStateKey = null, withMultipart = false) {
  return useAPI(withStore, requestStateKey, withMultipart, async (err) => {
    if (err.message === 'Request aborted') {
      return;
    }

    if (err.response && err.response.status === 401) {
      alert('This page has expired.');
      await loginWithRedirect();
    } else {
      throw err;
    }
  });
}

export const initAPIRequest = {
  inFlight: false,
  hasFinished: false,
  hasSucceeded: false,
  hasFailed: false,
  response: null,
  error: null,
};

export const inFlightAPIRequest = {
  inFlight: true,
  hasFinished: false,
  hasSucceeded: false,
  hasFailed: false,
  response: null,
  error: null,
};

export const successfulAPIRequest = {
  inFlight: false,
  hasFinished: true,
  hasSucceeded: true,
  hasFailed: false,
  response: null,
  error: null,
};

export const failedAPIRequest = {
  inFlight: false,
  hasFinished: true,
  hasSucceeded: false,
  hasFailed: true,
  response: null,
  error: null,
};
