<template>
  <div>
    <div class="flex flex-col">
      <div class="flex flex-row flex-grow space-x-[10px]">
        <div class="flex flex-grow border-[1px] border-white/30 rounded-lg p-[15px] bg-blue-700/30">
          <SelectionInfoCell
            title="Samples"
            :value="`${samples}`"
            :bigger-text="true"
          />
        </div>
        <div class="flex flex-grow border-[1px] border-white/30 rounded-lg p-[15px]  bg-blue-700/30">
          <SelectionInfoCell
            title="Subjects"
            :value="`${patients}`"
            :bigger-text="true"
          />
        </div>
      </div>
    </div>
    <ModelSystemInfo
      :model-system-sample-count="sunburstSelection.cstnSamples"
      :disease-hierarchy-full="sunburstSelection.diseaseHierarchyFull"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useSunburstStore } from '@/store/sunburst';
import ModelSystemInfo from '@/layouts/navbar/ModelSystemInfo';
import SelectionInfoCell from '@/layouts/navbar/SelectionInfoCell';
	
const sunburstStore = useSunburstStore();
const { sunburstSelection } = storeToRefs(sunburstStore);

defineProps({
  disease: { type: String, required: true },
  samples: { type: Number, required: true },
  patients: { type: Number, required: true },
});
</script>
