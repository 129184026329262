<template>
  <div class="flex flex-col flex-grow border-[1px] p-[10px] gap-[10px] border-white/30 rounded-lg bg-blue-700/30">
    <div class="flex flex-col flex-grow">
      <h3 class="font-bold h-2.5 text-[10px] tracking-widest uppercase text-white">
        Model Systems Samples
      </h3>
    </div>
    <div class="flex flex-col flex-grow space-x-[10px] gap-[15px]">
      <AppTooltip
        placement="bottom"
        hover
      >
        <template #default>
          <form
            action="https://models.stjude.cloud/api/v1/viewModelSystemSamples"
            method="post"
            target="_blank"
            class="w-full"
            @submit.prevent="fetchModelSystemSamples"
          >
            <input
              id="portalCode"
              type="hidden"
              name="portalCode"
              value="CSTN"
            >
            <input
              id="modelSampleIDs"
              type="hidden"
              name="modelSampleIDs"
            >
            <button
              id="modelSystemsBtn"
              type="submit"
              class="p-[15px] rounded-lg bg-white/10 w-full"
            >
              <div class="flex flex-row items-center text-left">
                <SelectionInfoCell
                  title="CSTN"
                  :value="`${modelSystemSampleCount}`"
                />
                <font-awesome-icon
                  :icon="['fas', 'arrow-up']" 
                  class="text-white rotate-45"
                />
              </div>
            </button>
          </form>
        </template>
        <template #content>
          <div class="p-[10px]">
            <div class="text-[10px] font-bold leading-[12px] tracking-[1px] text-neutral-700 uppercase mb-[10px]">
              CSTN - Childhood solid tumor network
            </div>
            <div class="text-[12px] leading-[18px] text-neutral-700">
              The <a
                href="https://cstn.stjude.cloud/"
                class="text-blue-400 underline"
                target="_blank"
              >CSTN Data Portal</a> provides
              access to orthotopic patient-derived xenograft models of childhood solid tumors and data.
            </div>
          </div>
        </template>
      </AppTooltip>
    </div>
  </div>
</template>

<script setup>
import { useAPI } from "@/api/api";
import SelectionInfoCell from "@/layouts/navbar/SelectionInfoCell";
import AppTooltip from "@/components/AppTooltip";

const props = defineProps({
  modelSystemSampleCount: { type: Number, required: true, default: 0 },
  diseaseHierarchyFull: {
    type: String,
    required: false,
    default: "", 
  },
});

const fetchModelSystemSamples = async (event) => {
  // Prevent the default form submission
  event.preventDefault();

  try {
    // Default to 'all' if diseaseHierarchyFull is null, specifically for returning to the root of sunburst after selecting a subtype
    const diseaseHierarchyFull = props.diseaseHierarchyFull || 'all';
    const response = await useAPI().get(`/modelsystems/samples/${diseaseHierarchyFull}`);
    const sampleNames = response.data.data;

    // Return if no samples are found
    if (!sampleNames) {
      return;
    }

    const modelsInput = document.getElementById("modelSampleIDs");
    modelsInput.value = JSON.stringify(sampleNames.split(";"));

    event.target.submit();
  } catch (error) {
    console.error("Something went wrong:", error.message);
  }
}
</script>
