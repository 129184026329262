<template>
  <div class="border-[1px] border-white/30 rounded-lg p-4">
    <h3 class="font-bold text-[10px] tracking-widest text-uppercase text-white mb-2 leading-[14px]">
      Diagnosis Selection
    </h3>
    <div class="font-light leading normal text-white text-[18px]">
      <div v-if="sunburstSelection.disease">
        <div
          v-for="(disease, index) in diseaseBreadcrumb"
          :key="disease"
          class="pb-[10px]"
        >
          <TransitionRoot
            appear
            :show="true"
            enter="transition duration-[750ms]"
            enter-from="opacity-0 -translate-y-full"
            enter-to="opacity-100 translate-y-0"
          >
            <!-- HM, ST, BT, GCT -->
            <div
              v-if="index === 0"
              class="flex flex-row overflow-hidden rounded-md bg-blue-700/40 z-[9]"
            >
              <div
                class="font-bold w-[34px] text-[14px] flex justify-center items-center rounded-l-md z-[6]"
                :class="tumorStyles[disease].background"
              >
                {{ disease }}
              </div>
              <div class="text-[12px] my-auto pl-[10px] py-[8px]">
                {{ tumorStyles[disease].title }}
              </div>
            </div>

            <!-- Intermediate Subtypes -->
            <div
              v-else-if="index < diseaseBreadcrumb.length - 1"
              class="flex flex-row"
            >
              <div class="w-[34px] flex shrink-0 items-center relative">
                <div class="w-[12px] h-[12px] mx-auto bg-[#1A4067] rounded-full intermediate" />
              </div>
              <div
                class="text-[12px] my-auto pl-[10px] py-[8px] border-[1px] border-white/10 flex flex-col flex-grow rounded-md opacity-60"
              >
                <div class="font-bold">
                  {{ disease }}
                </div>
                <div>{{ diseaseFullBreadcrumb[index] }}</div>
              </div>
            </div>

            <!-- Last/Active Subtype -->
            <div
              v-else
              class="flex flex-row"
            >
              <div class="w-[34px] my-auto flex shrink-0 relative">
                <div
                  class="w-[12px] h-[12px] mx-auto rounded-full current"
                  :class="tumorStyles[diseaseBreadcrumb[0]].background"
                />
              </div>
              <div
                class="text-[12px] my-auto pl-[10px] py-[8px] border-[1px] flex flex-col flex-grow rounded-md"
                :class="tumorStyles[diseaseBreadcrumb[0]].border"
              >
                <div class="font-bold">
                  {{ disease }}
                </div>
                <div>{{ sunburstSelection.disease }}</div>
              </div>
            </div>
          </TransitionRoot>
        </div>
      </div>

      <!-- Default -->
      <div v-else>
        <div
          v-for="diagnosis in initialDiagnosisSelection"
          :key="diagnosis.shortCode"
          class="flex flex-row my-2 bg-[#002044]/40 rounded-md overflow-hidden"
        >
          <div
            class="font-bold w-[34px] text-[14px] flex justify-center items-center"
            :class="tumorStyles[diagnosis.shortCode].background"
          >
            {{ diagnosis.shortCode }}
          </div>
          <div class="text-[12px] my-auto pl-[10px] py-[8px]">
            {{ diagnosis.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { TransitionRoot } from '@headlessui/vue';
import { useSunburstStore } from '@/store/sunburst';

const sunburstStore = useSunburstStore();

const { sunburstSelection } = storeToRefs(sunburstStore);

const tumorStyles = {
  HM: {
    background: 'bg-sunburst-hm',
    border: 'border-[#D01947]',
    title: 'Hematologic Malignancy',
  },
  ST: {
    background: 'bg-sunburst-st',
    border: 'border-[#F5AC0A]',
    title: 'Solid Tumor',
  },
  BT: {
    background: 'bg-sunburst-bt',
    border: 'border-[#35A7D3]',
    title: 'Brain Tumor',
  },
  GCT: {
    background: 'bg-sunburst-gct',
    border: 'border-[#858585]',
    title: 'Germ Cell Tumor',
  },
};

const initialDiagnosisSelection = [
  {
    shortCode: 'HM',
    title: 'Hematologic Malignancy',
  },

  {
    shortCode: 'ST',
    title: 'Solid Tumor',
  },

  {
    shortCode: 'BT',
    title: 'Brain Tumor',
  },

  {
    shortCode: 'GCT',
    title: 'Germ Cell Tumor',
  },
];

const diseaseBreadcrumb = computed(() => {
  return sunburstSelection.value.diseaseHierarchy.split('|');
});

const diseaseFullBreadcrumb = computed(() => {
  return sunburstSelection.value.diseasePath.split('|');
});
</script>

<style scoped>
.intermediate::after {
  content: '';
  position: absolute;
  left: 16px;
  width: 1px;
  min-height: 74px;
  max-height:84px;
  margin-top: -32px;
  background:#1A4067;
  z-index: 5;
}

.current::after {
  content: '';
  position: absolute;
  left: 16px;
  width: 1px;
  min-height: 42px;
  margin-top: -42px;
  background: #1A4067;
  z-index: 5;
}
</style>
